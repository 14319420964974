<template>
  <v-form
    ref="form"
    lazy-validation
    class="app-form"
    @submit.prevent="onSubmit"
  >
    <v-expansion-panels
      v-model="panel"
      class="app-expansion-panels"
    >
      <v-expansion-panel @click.stop="changeCollapse">
        <v-expansion-panel-header class="app-expansion-panel-header mb-0">
          <h4 class="text">
            <v-icon>filter_alt</v-icon>
            <span>Filtrar Usuários</span>
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="app-expansion-panel-content">
          <v-row class="mt-3">
            <v-col
              :lg="6"
              :md="6"
              :sm="12"
              :xs="12"
            >
              <v-text-field
                v-model="table.paginate.input.name"
                :clearable="true"
                label="Nome "
                placeholder="Ex:. João"
                outlined
                dense
              />
            </v-col>
            <v-col
              :lg="6"
              :md="6"
              :sm="12"
              :xs="12"
            >
              <v-text-field
                v-model="table.paginate.input.email"
                :clearable="true"
                label="Email "
                placeholder="Ex:. joao@gmail.com"
                outlined
                dense
              />
            </v-col>
          </v-row>
          <v-divider />
          <div class="mt-3 d-flex">
            <v-spacer />
            <v-btn
              outlined
              color="error"
              type="button"
              class="mr-2"
              @click.prevent.stop="clearFilter"
            >
              <v-icon size="14">
                delete
              </v-icon>Limpar Filtros
            </v-btn>
            <v-btn
              depressed
              color="success"
              type="submit"
            >
              Filtrar
            </v-btn>
          </div>

          <section
            v-if="table.isLoading"
            class="loading"
          >
            <v-progress-circular
              indeterminate
              color="success"
            />
          </section>
          <div id="endForm" />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    data: () => ({
      panel: null
    }),
    computed: {
      ...mapState('usuario', ['table'])
    },
    methods: {
      async onSubmit() {
        await this.search();
        this.$vuetify.goTo('#endForm');
      },
      clearFilter() {
        this.clear();
        this.search();
      },
      changeCollapse() {},
      ...mapActions('usuario', { search: 'SEARCH' }),
      ...mapActions('usuario', { clear: 'CLEAR' }),
      ...mapActions('usuario', { changePage: 'CHANGE_PAGE' })
    },
  };
</script>
